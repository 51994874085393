'use client';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
export function Scripts(): ReactNode {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return <></>;
  }

  return (
    <>
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="a1c040" />
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
    </>
  );
}
