import type { SignInWithOAuthCredentials } from '@supabase/gotrue-js';
import { useSupabase } from './use-supabase';
import useMutation from 'swr/mutation';

export function useSignInWithProvider() {
  const client = useSupabase();
  const key = ['auth', 'sign-in-with-provider'];

  return useMutation(
    key,
    async (_, { arg: credentials }: { arg: SignInWithOAuthCredentials }) => {
      return (
        client.auth
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          .signInWithOAuth(credentials as any)
          .then((response) => {
            if (response.error) {
              throw response.error.message;
            }

            return response.data;
          })
      );
    }
  );
}
